<template>
  <v-card class="ma-4 rounded-xl" variant="outlined">
    <v-card-title class="HeaderTitle">
      <v-toolbar color="primary" density="compact" flat>
        <v-toolbar-title class="textcolor"> Standrohr </v-toolbar-title>
      </v-toolbar>
    </v-card-title>

    <v-card-text>
      <v-col v-if="loading == false">
        <v-form ref="klasse">
          <v-row class="ma-1">
            <v-select v-if="Vorhanden('Bauform') == true" v-model="Geraeteklasse" :items="Geraeteklassen"
              :label="store.state.GeraeteklassenDisplayName" color="primary" item-title="Bezeichnung" density="compact"
              no-data-text="Keine Daten vorhanden" variant="outlined"
              :rules="[rules.required('Geraeteklasse', Geraeteklasse)]" @update:modelValue="cbGeraeteklasseChanged()">
            </v-select>
          </v-row>
        </v-form>

        <v-form ref="form">
          <v-row class="ma-1" v-if="Vorhanden('Bauform') == true">
            <v-select v-model="store.state.Bauform" :items="Geraetetypen" item-title="Bezeichnung"
              :label="store.state.BauformDisplayName" color="primary" variant="outlined" density="compact"
              no-data-text="Keine Daten vorhanden" :rules="[rules.required('Bauform', store.state.Bauform)]" />
          </v-row>

          <v-row class="ma-1" v-if="Vorhanden('Konzessionsgebiet') == true">
            <v-combobox v-model="store.state.Konzessionsgebiet" :items="Konzessionsgebiete"
              :label="store.state.KonzessionsgebietDisplayName" color="primary" variant="outlined" density="compact"
              no-data-text="Keine Daten vorhanden"
              :rules="[rules.required('Konzessionsgebiet', $store.state.Konzessionsgebiet),]" />
          </v-row>

          <v-row class="ma-1" v-if="Vorhanden('Einsatzstelle') == true">
            <v-text-field v-model="store.state.Einsatzstelle" :label="store.state.EinsatzstelleDisplayName"
              color="primary" variant="outlined" density="compact"
              :rules="[rules.required('Einsatzstelle', store.state.Einsatzstelle),]" />
          </v-row>

          <v-row class="ma-1">
            <v-combobox v-if="Vorhanden('VoraussichtlicheDauer') == true" v-model="store.state.VoraussichtlicheDauer"
              :items="VoraussichtlicheDauerMonth" :label="store.state.VoraussichtlicheDauerDisplayName" prepend-icon="" color="primary" variant="outlined"
              density="compact"
              :rules="[rules.required('VoraussichtlicheDauer', store.state.VoraussichtlicheDauer),]" />

            <v-spacer v-if="Vorhanden('Langzeitmiete') == true && Vorhanden('VoraussichtlicheDauer') == true" />
            <v-checkbox v-if="Vorhanden('Langzeitmiete') == true" v-model="store.state.Langzeitmiete"
              :label="store.state.LangzeitmieteDisplayName" color="primary" density="compact"
              :rules="[rules.required('Langzeitmiete', store.state.Langzeitmiete)]" />
          </v-row>

          <v-row v-if="store.state.Bankenmodul == true && Vorhanden('Zahlungsart') == true" class="ma-1">
            <v-select v-model="store.state.Zahlungsart" :items="store.state.BezahlItems"
              :label="store.state.ZahlungsartDisplayName" color="primary" variant="outlined" density="compact"
              :rules="[rules.required('Zahlungsart', store.state.Zahlungsart)]" />
          </v-row>

          <v-card flat class="ma-1" color="background">
            <v-card-title> Ausgabe an (falls bekannt) </v-card-title>
            <v-card-text>
              <v-row class="ma-1">
                <v-text-field v-if="Vorhanden('AusgabeName') == true" style="margin-right: 0.5em"
                  v-model="store.state.AusgabeName" :label="store.state.AusgabeNameDisplayName" color="primary"
                  variant="outlined" density="compact"
                  :rules="[rules.required('AusgabeName', store.state.AusgabeName)]" />
                <v-text-field v-if="Vorhanden('AusgabeVorname') == true" v-model="store.state.AusgabeVorname"
                  :label="store.state.AusgabeVornameDisplayName" color="primary" variant="outlined" density="compact"
                  :rules="[rules.required('AusgabeVorname', store.state.AusgabeVorname)]" />
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>

      <v-row v-if="Vorhanden('SonstigeButtons') == true" class="mt-1 mr-2 ml-3 mb-1">
        <sub>
          <h1>*</h1>
        </sub>
        <h4>Pflichtfelder</h4>
      </v-row>

      <v-row class="ma-2" v-if="loading == true">
        <Loadingscreen />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Loadingscreen from "@/components/main/loadingscreen.vue";
import dbhelper from "@/plugins/dbHelper.js";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

defineExpose({
  RefreshSeitenInhalt,
  checkvalidate,
  resetFelder,
});

//Variablen
const store = useStore();

var form = ref(null);
var klasse = ref(null);

var loading = ref(false);

var Konzessionsgebiete = ref([
  "Mönchengladbach",
  "Viersen",
  "Tönisvorst",
  "Grevenbroich",
  "Ohne",
]);

var rules = ref({
  required(Feld, value) {
    var istPflichtfeld = false;
    store.state.VertragsPflichtfelder.forEach((element) => {
      if (element == Feld) istPflichtfeld = true;
    });

    if (istPflichtfeld == true) {
      return !!value || "Pflichtfeld";
    } else return true;
  },

  EMail: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Ungültige E-Mail";
  },
});

var Geraeteklassen = ref([]);
var Geraeteklasse = ref({});
// Array der Bezeichnung der Gerätetypen
var Geraetetypen = ref([]);
var GeraetetypenDB = [];
var Fehler = ref(false);

var VoraussichtlicheDauerMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

//functions
onMounted(() => {
  RefreshSeitenInhalt();
});

async function RefreshSeitenInhalt() {
  loading.value = true;
  var Geraeteklasseurl = "GETGeraeteklassen";
  var response = await dbhelper.dbload(Geraeteklasseurl);
  var Geraeteklassendb = JSON.parse(response.DataArray);

  Geraeteklassen.value = [];
  Geraeteklassendb.forEach((element) => {
    // Nur Klassen für Standrohre anzeigen ! 0 = Standrohr aus der Enumeration
    if (element.Art == 0)
      Geraeteklassen.value.push(element);
  });

  GeraetetypenDB.value = [];
  var Geraetetypenurl = "GETGeraetetypen";
  response = await dbhelper.dbload(Geraetetypenurl);
  if (response.result.erfolgJN == "J") {
    GeraetetypenDB.value = JSON.parse(response.DataArray);
  } else {
    store.state.FehlerCode = 202;
    store.state.FehlerText = response.result.fehlertext;
    Fehler.value = true;
  }

  Geraeteklasse.value = Geraeteklassen.value[0].Bezeichnung;
  cbGeraeteklasseChanged();
  loading.value = false;
};

function cbGeraeteklasseChanged() {
  console.log('cbGeraeteklasseChanged');
  Geraetetypen.value = [];
  if (Geraeteklasse.value != '') {
    Geraeteklassen.value.forEach(element2 => {
      if (element2.Bezeichnung == Geraeteklasse.value) {
        GeraetetypenDB.value.forEach((element) => {
          if (element.GeraeteklasseID == element2.GeraeteklasseID && element.AnzahlImLager > 0) {
            Geraetetypen.value.push(element);
          }
        });
      }
    });
  }

  store.state.Bauform = "";
}

function Vorhanden(Feld) {
  var FeldNichtvorhandewn = false;
  store.state.FelderNichtAnzeigen.forEach((element) => {
    if (element == Feld) FeldNichtvorhandewn = true;
  });
  if (FeldNichtvorhandewn == true) return false;
  else return true;
}

async function checkvalidate() {
  var result = false;
  let test = await form.value.validate();
  if (test.valid == true) result = true;
  else result = false;

  let test2 = await klasse.value.validate();
  if (test2.valid == true && result == true) result = true;
  else result = false;

  return result;
}

function resetFelder() {
  Geraetetypen.value = [];
  klasse.value.reset();
  form.value.reset();

  if (Geraeteklassen.value.length == 1) {
    Geraeteklasse.value = Geraeteklassen.value[0].Bezeichnung;
    cbGeraeteklasseChanged();
  }
}
</script>