<template>
  <v-form ref="form">
    <v-card class="ma-4" variant="outlined">
      <v-card-title class="HeaderTitle">
        <v-toolbar color="primary" density="compact" flat>
          <v-toolbar-title class="textcolor">
            Weitere Vertragsfragen
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-col v-if="loading == false">
          <v-row class="ma-1" v-if="Vorhanden('ServicePoint') == true">
            <v-select v-model="store.state.ServicePoint" :items="Servicepoints"
              :label="store.state.ServicePointDisplayName" color="primary" variant="outlined" density="compact"
              no-data-text="Keine Daten vorhanden" :rules="[
          rules.required('ServicePoint', store.state.ServicePoint),
        ]">
            </v-select>
          </v-row>

          <v-row class="ma-1">
            <v-text-field v-if="Vorhanden('Einsatzort') == true" style="margin-right: 0.5em"
              v-model="store.state.Einsatzort" :label="store.state.EinsatzortDisplayName" color="primary"
              variant="outlined" density="compact" :rules="[rules.required('Einsatzort', store.state.Einsatzort)]">
            </v-text-field>

            <v-checkbox v-if="Vorhanden('MitAbwasser') == true" v-model="store.state.MitAbwasser"
              :label="store.state.MitAbwasserDisplayName" color="primary" density="compact"
              :rules="[rules.required('MitAbwasser', store.state.MitAbwasser)]"></v-checkbox>
          </v-row>

          <v-row class="ma-1" v-if="Vorhanden('Entnahmezweck') == true">
            <v-text-field v-model="store.state.Entnahmezweck" :label="store.state.EntnahmezweckDisplayName"
              color="primary" variant="outlined" density="compact" :rules="[
          rules.required('Entnahmezweck', $store.state.Entnahmezweck),
        ]">
            </v-text-field>
          </v-row>

          <v-row class="ma-1" v-if="Vorhanden('StueckHydrantenschluessel') == true">
            <v-combobox v-model="store.state.StueckHydrantenschluessel" :items="AnzahlHydrantenschluessel"
              :label="store.state.StueckHydrantenschluesselDisplayName" color="primary" variant="outlined" 
              density="compact" :rules="[rules.requiredHydrant('StueckHydrantenschluessel',store.state.StueckHydrantenschluessel)]"></v-combobox>
          </v-row>

          <v-row class="ma-1" v-if="Vorhanden('Bemerkungen') == true">
            <v-textarea v-model="store.state.Bemerkungen" :label="store.state.BemerkungenDisplayName" color="primary"
              variant="outlined" density="compact" :rules="[rules.required('Bemerkungen', store.state.Bemerkungen)]"
              auto-grow>
            </v-textarea>
          </v-row>
        </v-col>

        <v-row class="ma-2" v-if="loading == true">
          <Loadingscreen />
        </v-row>
        <v-row v-if="Vorhanden('SonstigeButtons') == true" class="mr-2 ml-3 mb-1">
          <sub>
            <h1>*</h1>
          </sub>
          <h4>Pflichtfelder</h4>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script setup>
import Loadingscreen from "@/components/main/loadingscreen.vue";
import dbhelper from "@/plugins/dbHelper.js";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

defineExpose({
  checkvalidate,
  resetFelder,
});

const Servicepointurl = "GETServicepoints";
const store = useStore();

var form = ref(null);
var loading = ref(false);

var Servicepoints = ref([]);
var AnzahlHydrantenschluessel = ref([0, 1]);

var rules = ref({
  required(Feld, value) {
    var istPflichtfeld = false;
    store.state.VertragsPflichtfelder.forEach((element) => {
      if (element == Feld) istPflichtfeld = true;
    });
    if (istPflichtfeld == true) return !!value || "Pflichtfeld";
    else return true;
  },
  requiredHydrant(Feld,value) {
    var istPflichtfeld = false;
    store.state.VertragsPflichtfelder.forEach((element) => {
      if (element == Feld) istPflichtfeld = true;
    });

    if (value == 0 || value==1) {
     
        return true;
    } else
      return !!value || "Pflichtfeld";
  },
  EMail: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Ungültige E-Mail";
  },
});

onMounted(async () => {
  loading.value = true;
  var response = await dbhelper.dbload(Servicepointurl);
  var ServicepointsDB = JSON.parse(response.DataArray);
  ServicepointsDB.forEach((element) => {
    Servicepoints.value.push(element.Bezeichnung);
  });
  loading.value = false;

  if (Servicepoints.value.length == 1)
    store.state.ServicePoint = Servicepoints.value[0];
});

function Vorhanden(Feld) {
  var FeldNichtvorhandewn = false;
  store.state.FelderNichtAnzeigen.forEach((element) => {
    if (element == Feld) {
      FeldNichtvorhandewn = true;
    }
  });
  if (FeldNichtvorhandewn == true) return false;
  else return true;
}

async function checkvalidate() {
  let test = await form.value.validate();
  if (test.valid == true) return true;

  return false;
}
function resetFelder() {
  form.value.reset();
  store.state.ServicePoint = Servicepoints.value[0];
}
</script>