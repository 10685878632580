import axios from "axios";


export class API {
    //    Base_url = 'http:' + window.location.origin.split(':')[1] + ':5044';

    /* methods */
    async loadSettings(url) {
        const res = await axios.get(url)
        res.data = res.data.replaceAll('&quot;', '"')
        return JSON.parse(res.data);
    }

    async dbload(url) {
        var res = {}
        res = await axios.post(url)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = res.data.replaceAll('&amp;', '&');    
                res.data = JSON.parse(res.data);
                return res;
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.data.erfolgJN = 'N'
                return res
            })
        return res.data
    }

    async NeuerVertrag(url, Vertrag) {
        var data = new FormData();

        data.append('AVertrag', JSON.stringify(Vertrag));
        const res = await axios.post(url, data)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res.data
    }

    //User functions
    async sendNewUser(url, user) {
        var data = new FormData();

        data.append('AUserData', JSON.stringify(user));

        var res = {}
        res.data = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }
        res = await axios.post(url, data)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res
    }

    async Login(url, User) {
        var data = new FormData();
        data.append('ALogin', JSON.stringify(User.Login));
        data.append('APassword', JSON.stringify(User.Password));
        var res = await axios.post(url, data)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data);
                return res
            })
            .catch((error) => {
                console.log('Login:');
                console.log(error);
                res = {
                    data: {
                        result: {
                            erfolgJN: 'N',
                            fehlertext: error.message,
                        }
                    }
                }
                return res
            })
        return res
    }

    async Logout(url) {
        var res = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }
        res = await axios.post(url)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res
    }

    async SendToken(url, Token) {

        var data = new FormData();
        data.append('AToken', Token);

        var res = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }
        res = await axios.post(url, data)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res
    }

    async checkResetToken(url, Token) {
        var data = new FormData();
        data.append('AToken', JSON.stringify(Token));
        var res = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }
        res = await axios.post(url, data)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res
    }

    async sendResetEMail(url, EMail) {
        var data = new FormData();
        data.append('AEMail', JSON.stringify(EMail));

        var res = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }
        res = await axios.post(url, data)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res
    }

    async ResetPassword(url, Token, Password) {
        var data = new FormData();
        data.append('AToken', JSON.stringify(Token));
        data.append('APassword', JSON.stringify(Password));

        var res = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }
        res = await axios.post(url, data)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res
    }

    //Account functions

    async ChangeEMail(url, EMail) {
        var data = new FormData();
        data.append('AEMail', JSON.stringify(EMail));

        var res = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }
        res = await axios.post(url, data)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {

                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res

            })
        return res
    }

    async CodeRequest(url, Seed, EMail) {
        var data = new FormData();
        data.append('ARandom', Seed)
        data.append('AEMail', EMail);


        var res = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }
        res = await axios.post(url, data)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })

        return res
    }

    async CheckCode(url, Zufallswert, EMail, Code) {
        var data = new FormData();
        data.append('ACode', JSON.stringify(Code));
        data.append('AEMail', JSON.stringify(EMail));
        data.append('ARandom', JSON.stringify(Zufallswert));
        var res = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }

        res = await axios.post(url, data)
            .then((res) => {
                console.log('then:' + res)
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res
    }



    async ChangePassword(url, OldPassword, NewPassword) {
        var data = new FormData();
        data.append('AOldPassword', JSON.stringify(OldPassword));
        data.append('ANewPassword', JSON.stringify(NewPassword));

        var res = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }

        res = await axios.post(url, data)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res
    }

    async ChangeBenutzer(url, NewBenutzer) {
        var data = new FormData();
        data.append('ABenutzer', JSON.stringify(NewBenutzer));

        var res = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }

        res = await axios.post(url, data)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res
    }

    async ChangeKontakt(url, Kontakt) {
        var data = new FormData();
        data.append('AFirma', JSON.stringify(Kontakt.Firma));
        data.append('AAnrede', JSON.stringify(Kontakt.Anrede));
        data.append('AAusweisnummer', JSON.stringify(Kontakt.Ausweisnummer));

        data.append('AVorname', JSON.stringify(Kontakt.Vorname));
        data.append('AWeitererVorname', JSON.stringify(Kontakt.WeitererVorname));
        data.append('ANachname', JSON.stringify(Kontakt.Nachname));

        data.append('ATelefon', JSON.stringify(Kontakt.Telefon));
        data.append('ATelefon2', JSON.stringify(Kontakt.Telefon2));
        data.append('AMobil', JSON.stringify(Kontakt.Mobil));
        data.append('AStrasse', JSON.stringify(Kontakt.Strasse));
        data.append('AHausnummer', JSON.stringify(Kontakt.Hausnummer));
        data.append('AHausNrErg', JSON.stringify(Kontakt.HausNrErg));
        data.append('APLz', JSON.stringify(Kontakt.Plz));
        data.append('AOrt', JSON.stringify(Kontakt.Ort));
        data.append('ALand', JSON.stringify(Kontakt.Land));

        var res = {}
        res.data = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }

        res = await axios.post(url, data)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                if (res.data.result.erfolgJN == 'J')
                    res.data.Kontakt = JSON.parse(res.data.Kontakt)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res
    }


    // Dokumente functions

    async sendDokument(url, Dokument) {
        var data = new FormData();

        data.append('ABezeichnung', JSON.stringify(Dokument.Bezeichnung))
        data.append('AArt', JSON.stringify(Dokument.Art))
        if (Dokument.PDFFile.type == "application/pdf")
            data.append('ADatenformat', "pdf")
        if (Dokument.PDFFile.type == "image/jpg")
            data.append('ADatenformat', "jpg")
        if (Dokument.PDFFile.type == "image/jpeg")
            data.append('ADatenformat', "jpeg")
        if (Dokument.PDFFile.type == "image/png")
            data.append('ADatenformat', "png")

        data.append('APDFFile', Dokument.PDFFile);

        var res = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }
        //  files = Dokument.PDFFile[0]
        res = await axios.post(url, data, {
            maxContentLength: 100000000,
            maxBodyLength: 100000000,

        }

        )
            .then((res) => {

                res.data = res.data.Data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data);

                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res
    }

    async getDokument(url, Dokument) {
        var data = new FormData();

        data.append('ABezeichnung', JSON.stringify(Dokument.Bezeichnung))
        data.append('AArt', JSON.stringify(Dokument.Art))
        data.append('AGUID', JSON.stringify(Dokument.GUID))

        var res = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }
        res = await axios.post(url, data)
            .then((res) => {
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res
    }


    async ImportDokumnentenliste(url) {
        var res = {
            erfolgJN: '',
            fehlertext: '',
            hinweistext: '',
            resultcount: 0,
        }

        res = await axios.post(url)
            .then((res) => {
                
                res.data = res.data.replaceAll("&quot;", '"');
                res.data = JSON.parse(res.data)
                return res
            })
            .catch((error) => {
                console.log('catch:' + error)
                res.erfolgJN = 'N'
                return res
            })
        return res
    }
}


const mapiInstance = new API()
export default mapiInstance