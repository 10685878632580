<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useLocale } from 'vuetify'

const { current } = useLocale();
var locale = "de";
onMounted(() => {
  changeLocale(locale)
})

function changeLocale(locale) {
  current.value = locale;
}

</script>
